import Vue from 'vue';
import Router from 'vue-router';
//引入相关页面
//vue引入路由插件
Vue.use(Router);

export default new Router({
  mode: 'history',     //用来消除路径中的#/
  routes: [            //路由数组，其中每个对象都是一个页面信息
    {
      path: '/',       //虚拟路径path，也就是浏览器中的路径
      title: '首页',  
      component:() => import('../views/PageA.vue'),  //组件页面地址
      meta:{title: '首页'}
    },
    {
      path: '/ThinkTank',       //虚拟路径path，也就是浏览器中的路径
      title: '环保智库',  
      component:() => import('../views/PageB.vue'),  //组件页面地址
      meta:{title: '环保智库'}
    },
    {
      path: '/INFORMATION',       //虚拟路径path，也就是浏览器中的路径
      title: '行业资讯',  
      component:() => import('../views/PageC.vue'),  //组件页面地址
      meta:{title: '行业资讯'}
    },
    {
      path: '/PLATFORM',       //虚拟路径path，也就是浏览器中的路径
      title: '智慧平台',  
      component:() => import('../views/PageD.vue'),  //组件页面地址
      meta:{title: '智慧平台'}
    },
    {
      path: '/SERVICES',       //虚拟路径path，也就是浏览器中的路径
      title: '第三方服务',  
      component:() => import('../views/PageE.vue'),  //组件页面地址
      meta:{title: '第三方服务'}
    },
    {
      path: '/CONTACTUS',       //虚拟路径path，也就是浏览器中的路径
      title: '联系我们',  
      component:() => import('../views/PageF.vue'),  //组件页面地址
      meta:{title: '联系我们'}
    },
    {
      path: '/DETAILS',       //虚拟路径path，也就是浏览器中的路径
      title: '新闻中心',  
      component:() => import('../views/Detail.vue'),  //组件页面地址
      meta:{title: '新闻中心'}
    },
  ]
});
