<template>
  <div>
    <div class="head">
      <div class="flexbetween flexalign">
        <img class="logoImg" src="../assets/logo.png" alt="">
        <div class="searchPart">
          <input class=headInput type="text" placeholder="请输入关键词" name="" id="">
          <img class="search" src="/image/searchIcon.gif" alt="">
        </div>
      </div>
      <!-- tabbar  -->
      <div class="tabbarPart flexbetween">
        <div class="items flexalign">
          <div class="tabList" :class="{active:tabIndex == index}" v-for="(item,index) in tabList" :key="index" 
          @click="tabCli(index)" > 
          <!-- @mouseenter="tabCli(index)" -->
            <div class="en" v-if="tabIndex == index">{{ item.english }}</div>
            <div class="zn">{{ item.name }}</div>
          </div>
        </div>
        <div class="phoneCode flex flexalign">
          <img src="../assets/phoneicon.png" alt="">
          <span>13666778899</span>
        </div>
      </div>
    </div>
     <!-- 组件 -->
     <template>
        <div style="width: 100%;">
          <!-- <Pagea v-show="tabIndex == 0" :usList="usList" :earlyList="earlyList"></Pagea> -->
          <slot></slot>
        </div>
      </template>
      <!-- footer -->
      <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
export default {
  components:{Footer},
  data (){
    return{
      tabList:[{name:'首页',english:'HOME'},{name:'环保智库',english:'THINK TANK'},{name:'行业资讯',english:'INFORMATION'},
        {name:'智慧平台',english:'PLATFORM'},{name:'第三方服务',english:'SERVICES'},{name:'联系我们',english:'CONTACT US'}],
      tabIndex:0,
    }
  },
  methods:{
    tabCli(e){
      this.tabIndex = e
      if(e == 0){
        this.$router.push('/')
      }else if(e == 1){
        this.$router.push('/ThinkTank')
      }else if(e == 2){
        this.$router.push('/INFORMATION')
      }else if(e == 3){
        this.$router.push('/PLATFORM')
      }else if(e == 4){
        this.$router.push('/SERVICES')
      }else if(e == 5){
        this.$router.push('/CONTACTUS')
      }
    }
  },


}
</script>

<style>
*{
  margin: 0;padding: 0;
}
</style>
<style lang="scss" scoped>
  .head{
    padding: 30px 8% 0 7%;
    position: sticky;top: 0;left: 7%;z-index: 99;
    background: #fff;
    .logoImg{
      width: 400px;height: 50px;
    }
    .searchPart{
      position: relative;
      .headInput{
      width: 254px;
      height: 34px;
      border: 1px solid #9E9E9E;color: #949494;
      border-radius: 10px 0 0 10px;
      text-indent:2em;
      font-size: 13px;
      }
      .search{
        position: absolute;right: 0px;top: 1px;
        width: 40rpx;
        height: 34px;
      }
    }
   
  }
  .tabbarPart{
    width: 104%;
    margin-top: 20px;
    .items{
      width: 80%;
      cursor: pointer;
    }
    .tabList{
      width: 16%;padding: 16px 0;
      text-align: center;
      .en{
        font-size: 13px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #FFFFFF;
      }
      .zn{
        font-size: 13px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        white-space: nowrap;

      }
    }
    .active{
      background: #008FD7;
      color: #FFFFFF;
    }
    .phoneCode{
      width: 16%;
      img{
        width: 28px;
        height: 28px;margin-right: 4px;
      }
      span{
        // height: 27px;
        font-size: 18px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #ED8340;
      }
    }
  }
</style>>
