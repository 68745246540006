<template>
  <div class="footerPart">
    <div class=" flexbetween">
      <img class="gslogoName" type="image" src="../assets/b_logo.png" alt="">
      <div class="phones flexalign">
        <img class="phoneImg" src="../assets/b_phone.png" alt="">
        <div class="phonecode">19988776655</div>
      </div>
    </div>
    <div class="qrcode flexalign flexbetween">
      <div class="qrcodeText">
        地址：宁夏回族自治区中卫市沙坡头区应理南街东侧沙坡头水镇A7#楼1W12 <br/>
        Copyright © 2023 宁夏美佳蓝节能科技有限公司 版权所有 <a href="http://beian.miit.gov.cn"  target="_blank" style="color:#fff;"> 宁ICP备2024003706号-1 </a>
      </div>
      <img class="codeTwo" src="/image/b_code.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String
  }
}
</script>

<style lang="scss" scoped>
.footerPart{
    width: 100%;box-sizing: border-box;
    padding: 40px 6% 40px 5%;
    background: #252525;
    .gslogoName{
      width: 400px;height: 40px;
    }
    .phones{
      
      .phoneImg{
        width: 20px;height: 20px;margin-right: 16px;
      }
      .phonecode{
        font-size: 32px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #FFFEFE;
      }
    }
}
.qrcode{
  padding-right: 60px;
  margin-top: 20px;
  color: #fff;
  .qrcodeText{
    margin-top: 40px;
    line-height: 40px;
  }
  .codeTwo{
    width: 114px;height: 114px;
  }
}
</style>